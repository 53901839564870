import { createAction, props } from '@ngrx/store';
import { ProjectDetailsWorkCardModel } from '../../widgets/staar/models/projectdetails-workcard';
import {
  AuditClosureReviewResModel,
  RoutineInspectionFlagResModel,
} from '../../widgets/staar/models/request-payload';
import { WorkCardFilesModel } from '../../widgets/staar/models/project-files';
import { RequiredPartsModel } from '../../widgets/staar/models/my-work-card';
import { ToolsModel } from '../../widgets/staar/models/tools-history';
import { workCardStepsModel } from '../../widgets/staar/models/workCardSteps';
import {
  BasicAnnotation,
  CIRStep,
  PreAuditChecklistItem,
} from '../../widgets/staar/service-modules/data-accessors/work-card-accessor.service';
import { GetLogPageNumbersResponseModel } from '../../widgets/staar/models/log-page-numbers';
import { CircuitBreakerModel } from '../../widgets/staar/models/circuit-breaker';

export const setSelectedWorkCard = createAction(
  '[Work Cards] Set Selected Work Card',
  props<{ workCard?: ProjectDetailsWorkCardModel; workCardId?: number }>()
);

export const fetchWorkCard = createAction(
  '[Work Cards] Fetch Work Card',
  props<{ workCardId: number }>()
);

// This will replace all workcards with the
// the passed work card list. If you want to *add* work cards,
// use the addWorkCards action below
export const setWorkCards = createAction(
  '[Work Cards] Set Work Cards',
  props<{ workCards: ProjectDetailsWorkCardModel[] }>()
);

export const patchWorkCard = createAction(
  '[Work Cards] Patch Work Card',
  props<{ workCardId: number; patch: Partial<ProjectDetailsWorkCardModel> }>()
);

export const addWorkCards = createAction(
  '[Work Cards] Add Work Cards',
  props<{ workCards: ProjectDetailsWorkCardModel[] }>()
);

export const setRoutineFlags = createAction(
  '[Work Cards] Set Routine Flags',
  props<{ workCardId: number; flags: RoutineInspectionFlagResModel[] }>()
);

export const fetchContinuationSheets = createAction(
  '[Work Card] Fetch Continuation Sheets',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setContinuationSheets = createAction(
  '[Work Cards] Set Routine Continuation Sheets',
  props<{ workCardId: number; continuationSheets: CIRStep[] }>()
);

export const fetchCIRSteps = createAction(
  '[Work Card] Fetch CIR Steps',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setCIRSteps = createAction(
  '[Work Cards] Set CIR Steps',
  props<{ workCardId: number; steps: CIRStep[] }>()
);

export const fetchWorkCardFiles = createAction(
  '[Work Cards] Fetch File Object',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setWorkCardFile = createAction(
  '[Work Cards] Set File Object',
  props<{ workCardId: number; file: WorkCardFilesModel }>()
);

export const fetchAllDocuments = createAction(
  '[Work Cards] Fetch All Document File Objects',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setAllDocuments = createAction(
  '[Work Cards] Set All Document File Objects',
  props<{ workCardId: number; allDocuments: any }>()
);

export const fetchAttachments = createAction(
  '[Work Cards] Fetch Attachment File Objects',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setAttachments = createAction(
  '[Work Cards] Set Attachment File Objects',
  props<{ workCardId: number; attachments: WorkCardFilesModel[] }>()
);

export const fetchTechData = createAction(
  '[Work Cards] Fetch Tech Data File Objects',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setTechData = createAction(
  '[Work Cards] Set Tech Data File Objects',
  props<{ workCardId: number; techData: WorkCardFilesModel[] }>()
);

export const setNRCSteps = createAction(
  '[Work Cards] Set NRC Steps',
  props<{ workCardId: number; nrcSteps: workCardStepsModel[] }>()
);

export const setAuditQueue = createAction(
  '[Work Cards] Set Audit Queue',
  props<{ auditQueue: number[] }>()
);

export const setCustomerWorkCardQueue = createAction(
  '[Work Cards] Set Customer Work Card Queue',
  props<{ queue: number[] }>()
);

export const fetchParts = createAction(
  '[Work Card] Fetch Required Parts',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const fetchAuditClosureReviews = createAction(
  '[Work Card] Fetch Audit Closure Reviews',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setAuditClosureReviews = createAction(
  '[Work Card] Set Audit Closure Reviews',
  props<{ workCardId: number; auditReviews: AuditClosureReviewResModel[] }>()
);

export const setParts = createAction(
  '[Work Card] Set Required Parts',
  props<{ workCardId: number; parts: RequiredPartsModel[] }>()
);

export const fetchTools = createAction(
  '[Work Card] Fetch Tools',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setTools = createAction(
  '[Work Card] Set Tools',
  props<{ workCardId: number; tools: ToolsModel[] }>()
);

export const fetchSceptreDetails = createAction(
  '[Work Card] Fetch SCEPTRE Details',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setSceptreRequired = createAction(
  '[Work Card] Set SCEPTRE Required',
  props<{ workCardId: number; sceptreRequired: boolean }>()
);

export const setAnnotation = createAction(
  '[Work Card] Set Annotation',
  props<{ fileObject: WorkCardFilesModel; annotation: BasicAnnotation }>()
);

export const deleteAnnotation = createAction(
  '[Work Card] Delete Annotation',
  props<{ fileObject: WorkCardFilesModel; annotation: BasicAnnotation }>()
);

export const setPreAuditChecklist = createAction(
  '[Work Card] Set Pre-Audit Checklist',
  props<{ workCardId: number; preAuditChecklist: PreAuditChecklistItem[] }>()
);

export const fetchLogPages = createAction(
  '[Work Card] Fetch Log Pages',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setLogPages = createAction(
  '[Work Card] Set Log Pages',
  props<{ workCardId: number; logPages: GetLogPageNumbersResponseModel[] }>()
);

export const noop = createAction('[NO OP] noop');

export const fetchCircuitBreakers = createAction(
  '[Work Card] Fetch Circuit Breakers',
  props<{ workCardDetails: ProjectDetailsWorkCardModel }>()
);

export const setCircuitBreakers = createAction(
  '[Work Card] Set Circuit Breakers',
  props<{ workCardId: number; circuitBreakers: CircuitBreakerModel[] }>()
);

export const setLastValidatedActionDate = createAction('[Work Card] Set Last Validated Action Date');

