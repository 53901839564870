import { Injectable } from '@angular/core';
import { PersistedValueEnum } from '../../../../shared/models/persisted-value-enum';
import { Observable, combineLatest } from 'rxjs';
import { ConcourseApiService } from '../../../../shared/services/concourse-api.service';
import { PathConstants } from '../../constants/mro-path';
import { map, tap } from 'rxjs/operators';
import { CustomerEmpVNumberReqModel, TokenResModel } from '../../models/request-payload';
import { EmployeeProfile } from '../../models/employee-profile';
import { CustomerAdminApiService } from '../../../../shared/services/customer-admin-api.service';

export interface UserDetails {
  id: number;
  email: string;
  employeeID: string;
  firstName: string;
  lastName: string;
  organizationId: number;
  division: string;
  phoneNumber: string;
  userName: string;
  userTypeId: number;
}

@Injectable()
export class UserAccessorService {
  constructor(
    private concourseApi: ConcourseApiService,
    private customerConcourseApi: CustomerAdminApiService
  ) {}

  public getToken(): string {
    return sessionStorage.getItem('token');
  }

  public generateAndSaveIdentityInfo(): Observable<string> {
    return combineLatest([this.fetchTokenInfo(), this.fetchUserRole()]).pipe(
      map(([tokenInfo, userRole]) => ({ tokenInfo, userRole })),
      tap(({ tokenInfo, userRole }) => {
        sessionStorage.setItem('token', tokenInfo?.token);
        localStorage.setItem('employeeNumber', tokenInfo?.employeeNumber);
        localStorage.setItem('divNo', String(tokenInfo?.divNo));
        localStorage.setItem('divName', tokenInfo?.divName);
        localStorage.setItem('divCount', String(tokenInfo?.divCount));

        localStorage.setItem('userRole', userRole);
      }),
      map(({ tokenInfo }) => tokenInfo.token)
    );
  }

  public getCustomerEmpVNumber(
    data: CustomerEmpVNumberReqModel
  ): Observable<EmployeeProfile> {
    return this.customerConcourseApi.getCustomerEmployeeVNumber(PathConstants.customerEmpVNumber, data)
      .pipe(map(res => res || null));
  }


  public getEmployeeInfo() {
    return {
      // userId: localStorage.getItem('userId'),
      employeeNumber: localStorage.getItem('employeeNumber'),
      divNo: +localStorage.getItem('divNo'),
      divName: localStorage.getItem('divName'),
      divCount: +localStorage.getItem('divCount'),
      userRole: this.getUserRole(),
    };
  }

  public fetchEmployeeProfile(params: {
    employeeNumber: string;
    divNo: number;
  }): Observable<EmployeeProfile> {
    const { employeeNumber, divNo } = params;
    return this.concourseApi
      .getEmployeeProfile(PathConstants.employeeProfile, {
        employeeNumber,
        divNo,
        token: this.getToken(),
      })
      .pipe(map(res => res || null));
  }

  public getCustomerInfo() {
    return {
      userId: localStorage.getItem('userId'),
    };
  }

  public getUserRole() {
    return localStorage.getItem('userRole');
  }

  public getUserDetails(): UserDetails {
    const userDetails = JSON.parse(
      localStorage.getItem(PersistedValueEnum.userDetails)
    );
    if (userDetails?.id) {
      return userDetails;
    }
    throw new Error('Could not parse userDetails from localstorage');
  }

  public fetchTokenInfo(): Observable<TokenResModel> {
    const { employeeID } = this.getUserDetails();
    return this.concourseApi
      .getToken(PathConstants.getToken, {
        employeeId: employeeID,
        application: 'CONCOURSE',
        channel: 'Browser',
      })
      .pipe(
        map((res) => {
          if (!res) {
            throw new Error(
              `User not found when fetching token for employee ID ${employeeID}`
            );
          }
          return res;
        })
      );
  }

  public fetchCustomerTokenInfo(employeeID): Observable<TokenResModel> {
    return this.concourseApi
      .getToken(PathConstants.getToken, {
        employeeId: employeeID,
        application: 'CONCOURSE',
        channel: 'Browser',
      })
      .pipe(
        map((res) => {
          if (!res) {
            throw new Error(
              `User not found when fetching token for employee ID ${employeeID}`
            );
          }
          return res;
        })
      );
  }

  public fetchUserRole(): Observable<string> {
    const userId = this.getUserDetails().id;
    return this.concourseApi.getUserRoleByUserId(userId).pipe(
      map((res) => {
        const role = res[0];
        return role?.normalizedName;
      })
    );
  }
}
