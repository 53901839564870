import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CustomerUser } from "../models/customer-user";
import { CustomerOrganization } from "../models/customer-organization";
import { ApiUtility } from "./api-utility";
import { HttpService } from "./http.service";
import { CustomerEmpVNumberReqModel } from "../../widgets/staar/models/request-payload";

@Injectable({
  providedIn: 'root',
})
export class CustomerAdminApiService {
  private readonly personaManagementPrefix = ApiUtility.personaManagementPrefix;
  modalData = {isModal :false ,assignedEmployeeList : '', }
  public employeeModal = new BehaviorSubject<boolean>(false);
  public employeeDetail = new BehaviorSubject<any>({});
  public getEmployeeList = new BehaviorSubject<any>(false);
  constructor(
    private httpService: HttpService,
  ) {}

  // Customer Organization APIs
  getAllCustomerOrganizations(): Observable<CustomerOrganization[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetAllCustomerOrganizations`
    ) as Observable<CustomerOrganization[]>;
  }

  getCustomerOrganizationById(id: string | number): Observable<CustomerOrganization> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetCustomerOrganizationById/${id}`
    ) as Observable<CustomerOrganization>;
  }

  insertCustomerOrganization(customerOrganization: CustomerOrganization): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}InsertCustomerOrganization`,
      customerOrganization
    );
  }

  updateCustomerOrganization(customerOrganization: CustomerOrganization): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}UpdateCustomerOrganization`,
      customerOrganization
    );
  }

  deleteCustomerOrganizationById(id: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteCustomerOrganizationById/${id}`
    );
  }

  // Customer User APIs
  getAllCustomerUsers(): Observable<CustomerUser[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetAllCustomerUsers`
     ) as Observable<CustomerUser[]>;
  }

  getCustomerUserById(id: string | number): Observable<CustomerUser> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetCustomerUserById/${id}`
    ) as Observable<CustomerUser>;
  }

  getCustomerUserByUserId(userId: string | number): Observable<any> {
    return this.httpService.get(
       `${this.personaManagementPrefix}GetCustomerUserByUserId/${userId}`
    ) as Observable<any>;
  }

  GetCustomerUserByUserIdAndDivision(userId: string | number, division: string | number): Observable<CustomerUser> {
    return this.httpService.get(
       `${this.personaManagementPrefix}GetCustomerUserByUserIdAndDivision/${userId}/${division}`
    ) as Observable<CustomerUser>;
  }

  getCustomerStampByUserId(userId: string | number): Observable<CustomerUser> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetCustomerStampByUserId/${userId}`
    ) as Observable<CustomerUser>;
  }

  getCustomerEmployeeVNumber(subUrl: string, data: CustomerEmpVNumberReqModel): Observable<any> {
    return this.httpService.get(
      `${subUrl}?divNo=${data.divNo}&token=${data.token}&customerNumber=${data.customerNumber}&employeeNumber=${data.employeeNumber}`
    );
  }

  insertCustomerUser(customerUser: CustomerUser): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}InsertCustomerUser`, customerUser
    );
  }

  updateCustomerUser(customerUser: CustomerUser): Observable<any> {
    return this.httpService.post(
      `${this.personaManagementPrefix}UpdateCustomerUser`, customerUser
    );
  }

  deleteCustomerUserById(id: string | number): Observable<any> {
    return this.httpService.delete(
      `${this.personaManagementPrefix}DeleteCustomerUserById/${id}`
    );
  }
}
