import { WorkCardFilesModel } from '../widgets/staar/models/project-files';
import { RoutineCustomerStepsResModel } from '../widgets/staar/models/request-payload';
import { ApplicationState } from './application-store';

export const selectedWorkCard = (state: ApplicationState) =>
  state?.workCards?.workCards[state.workCards.selectedWorkCardId];

export const lastValidatedActionDate = (state: ApplicationState) =>
  state?.workCards?.lastValidatedActionDate;

export const selectedWorkCardFileObject = (state: ApplicationState) =>
  state?.workCards?.workCardFiles[state.workCards.selectedWorkCardId];

export const selectedCIRSteps = (
  state: ApplicationState,
  options: { includeVoided?: boolean } = {}
): RoutineCustomerStepsResModel[] => {
  const allSteps =
    state?.workCards?.cirSteps[state.workCards.selectedWorkCardId] ?? [];

  const includeVoided = !!options?.includeVoided;
  return includeVoided
    ? allSteps
    : allSteps.filter((step) => step.ACTIVE !== 'N');
};
export const selectedNRCSteps = (state: ApplicationState) =>
  state?.workCards?.nrcSteps[state.workCards.selectedWorkCardId] ?? [];

export const selectedContinuationSheets = (state: ApplicationState) =>
  state?.workCards?.continuationSheets[state.workCards.selectedWorkCardId] ?? [];

export const selectedWorkCardAttachments = (state: ApplicationState) =>
  state?.workCards?.attachments[state.workCards.selectedWorkCardId] ?? [];

export const selectedWorkCardTechData = (state: ApplicationState) =>
  state?.workCards?.techData[state.workCards.selectedWorkCardId] ?? [];

export const selectedWorkCardTools = (state: ApplicationState) =>
  state.workCards?.tools[state.workCards.selectedWorkCardId] ?? [];

export const selectedWorkCardParts = (state: ApplicationState) =>
  state.workCards?.parts[state.workCards.selectedWorkCardId] ?? [];

export const selectRoutineFlags = (state: ApplicationState) =>
  state.workCards?.routineFlags[state.workCards.selectedWorkCardId] ?? [];

export const selectedPreAuditChecklist = (state: ApplicationState) =>
  state.workCards?.preAuditChecklist[state.workCards.selectedWorkCardId] ?? [];

export const selectedPreAuditChecklistOk = (state: ApplicationState) => {
  const checklist = selectedPreAuditChecklist(state);
  return checklist.filter((item) => item.ITEM_STATUS_FLAG === 'Y');
};

export const selectedContinuationSheetsOnly = (state: ApplicationState) => {
  const csList = selectedContinuationSheets(state);
  return csList.filter((item) => item.DOCUMENT_TYPE === 'CONTINUATION');
};

export const selectedPreAuditChecklistNotOk = (state: ApplicationState) => {
  const checklist = selectedPreAuditChecklist(state);
  return checklist.filter((item) => item.ITEM_STATUS_FLAG === 'N');
};

export const selectedLogPages = (state: ApplicationState) =>
  state.workCards?.logPages[state.workCards.selectedWorkCardId] ?? [];

export const selectAnnotationsForFile = (
  state: ApplicationState,
  fileObject: WorkCardFilesModel
) => {
  const currentCard = selectedWorkCard(state);
  const workCardId = currentCard?.WORK_CARD_ID;
  const fileObjectId = fileObject?.ID;

  const workCardFileObjects = state.workCards.annotations[workCardId] ?? {};
  const fileObjectAnnotations = workCardFileObjects[fileObjectId] ?? {};
  return Object.values(fileObjectAnnotations) ?? [];
};

export const selectEmployeeById = (state: ApplicationState, id: number) =>
  state.users.employees[id] ?? null;

export const selectedWorkCardClosureReviews = (state: ApplicationState) =>
  state.workCards?.auditClosureReviews[state.workCards.selectedWorkCardId] ??
  [];

export const selectedWorkCardCircuitBreakers = (state: ApplicationState) =>
  state.workCards?.circuitBreakers[state.workCards.selectedWorkCardId] ?? [];
