import { IonicColor } from "aar-ionic-component-library/dist/ngx-aar-ionic-library";
import { round } from "lodash";
import { workCardStepsModel, WorkCardStepsStatus } from "../models/workCardSteps";
import { formatNumber } from "@angular/common";

export class DataHelper {
  calculateDays(inductionDate: Date): number {
    let today = new Date();
    let localizedInductionDate = this.adjustForTimezone(new Date(inductionDate));

    let diffdifferenceInMilliseconds = Math.abs(today.getTime() - localizedInductionDate.getTime());
    let differenceInDays = Math.ceil(diffdifferenceInMilliseconds / (1000 * 3600 * 24));
    return differenceInDays;
  }

  calculateTargetCycleDays(inductionDate: Date, targetDate: Date): number {
    let localizedTargetDate = this.adjustForTimezone(new Date(targetDate));
    let localizedInductionDate = this.adjustForTimezone(new Date(inductionDate));

    let differenceInMilliseconds = Math.abs(localizedTargetDate.getTime() - localizedInductionDate.getTime());
    let differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
    return differenceInDays;
  }

  adjustForTimezone(date: Date): Date {
    let timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }

  calculateper(val1, val2) {
    const value1 = val1 ?? 0;
    const value2 = val2 ?? 0;
    let iconColor = "";
    let ionicColor = IonicColor.dark;
    let iconName = "";
    let actualPer: any = round((value1 / value2) * 100.0);
    let indicatorVal: any = (value1 / value2);
    if (actualPer >= 0 && actualPer < 80) {
      ionicColor = IonicColor.dark;
      indicatorVal = (value1 / value2);
    }
    if (actualPer >= 80 && actualPer < 100) {
      ionicColor = IonicColor.warning;
      indicatorVal = (value1 / value2);
      iconColor = "warning";
      iconName = "time";
    }
    if (actualPer >= 100) {
      ionicColor = IonicColor.danger;
      indicatorVal = (value1 / value2);
      iconColor = "danger";
      iconName = "alert-circle";
    }

    return {
      "ionicColor": ionicColor, "per": parseFloat(actualPer).toFixed(0), "indicatorVal": indicatorVal.toFixed(2),
      "iconColor": iconColor, "iconName": iconName
    };
  }

  DayOfCheck(val1, val2) {
    let value1 = val1;
    let value2 = val2;
    let ionicColor = IonicColor.dark;

    let actualPer: any = (value1 / value2) * 100;
    let indicatorVal: any = (value1 / value2);
    if (actualPer >= 100) {
      ionicColor = IonicColor.danger;
      indicatorVal = (value1 / value2);
    } else {
      ionicColor = IonicColor.dark;
      indicatorVal = (value1 / value2);
    }
    return {
      "ionicColor": ionicColor, "per": parseFloat(actualPer).toFixed(1), "indicatorVal": indicatorVal.toFixed(2),
    };
  }

  numberWithCommas(number: any) {
    if (number !== null) {
      let parts = number.toString().split(".");
      parts[0] = formatNumber(Number(parts[0]), 'en-US', '1.0-0');
      return parts.join(".");
    } else {
      return 'N/A';
    }
  }

  ellipseText(input: string, limit: number = 33) {
    if (input?.length > limit) {
      return input.substring(0, limit) + '...';
    }
    return input;
  }

  constructQueryString(params: any) {
    let newObj = {};
    Object.keys(params).forEach(function (key) {
      if (params[key] !== null && params[key] !== '')
        newObj[key] = params[key];
    });
    let queryString = Object.keys(newObj).map(key => key + '=' + newObj[key]).join('&');
    return queryString;
  }

  timeOnCard(per: number) {
    let ionicColor = IonicColor.dark;
    let indicatorVal: any = (per / 100);
    if (per >= 0 && per < 80) {
      ionicColor = IonicColor.dark;
    }
    if (per >= 80 && per < 100) {
      ionicColor = IonicColor.warning;
    }
    if (per >= 100) {
      ionicColor = IonicColor.danger;
    }
    return { "ionicColor": ionicColor, "indicatorVal": indicatorVal };
  }

  // Return technician and inspector status of work card steps.
  workCardStepsData(params: workCardStepsModel, isEditable?: boolean): WorkCardStepsStatus {
    if (this.checkRequestInspectionWithoutMechFlag(params)) {
      return this.updateWorkCardStepData(true, 'Add stamp', 'Request Inspection', true);
    }
    if (this.checkRequestInspectionWithMechFlag(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Request Inspection', true);
    }
    if (this.checkRequestInspectionWithoutMechFlagOpenNew(params)) {
      return this.updateWorkCardStepData(true, 'Add stamp', 'Request Inspection', true);
    }
    if (this.checkRequestInspectionWithMechFlagOpenNew(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Request Inspection', true);
    }
    if (this.checkRequestInspectionWithMechFlagInspFlag(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Request Re-Inspection', true);
    }
    if (this.checkRequestInspectionWithoutMechFlagInspFlag(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Request Re-Inspection', true);
    }

    // Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
    // Case 1: Handling the initial inspection rejection by inspector
    if (this.isInitialInspectionRejected(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Inspection Rejected', false);
    }

    // Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
    // Case 2: Handling a correction after the inspection was initially rejected
    if (this.isCorrectionAfterInspectionReject(params)) {
      return this.updateWorkCardStepData(true, 'Add stamp', 'Request Re-Inspection', true);
    }

    // Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
    // Case 3: Processing the scenario where an inspection is requested after a CIR rejection
    if (this.isInspectionRequestedAfterReject(params)) {
      return this.updateWorkCardStepData(false, params.STAMPED_BY_MECHANIC_DATE, 'Awaiting Inspection', isEditable);
    }

    // Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
    // Case 4: Handling a canceled inspection by a technician after CIR rejection
    if (this.isInspectionCanceledByTechnician(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Request Re-Inspection', true);
    }

    // Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
    // Case 5: Handling the scenario where an inspection is in progress after CIR rejection
    if (this.isInspectionInProgressAfterReject(params)) {
      return this.updateWorkCardStepData(false, '', 'Inspection In Progress', false);
    }

    if (this.checkAwaitingInpection(params)) {
      return this.updateWorkCardStepData(false, params.STAMPED_BY_MECHANIC_DATE, 'Awaiting Inspection', isEditable);
    }
    if (this.checkRequestInspectionWithMechFlagInspRejectFlag(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Inspection Rejected', false);
    }
    // for final buyback correct scenario
    if (this.checkFinalBuybackCorrected(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Inspection Rejected', false);
    }
     //-- Start
     if (this.checkAwaitingInpectionWithoutMechFlag(params)) {
      return this.updateWorkCardStepData(false, 'Add stamp', 'Awaiting Inspection', isEditable);
    }
    //--End
    if (this.checkInspectionInprogress(params)) {
      return this.updateWorkCardStepData(false, '', 'Inspection In Progress', isEditable);
    }
     //---Start
     if (this.checkInspectionInprogressWithoutMechFlag(params)) {
       return this.updateWorkCardStepData(false, 'Add stamp', 'Inspection In Progress', isEditable);
     }
     //--End
    if (this.checkStatusWithoutInsAndMechFlag(params)) {
      return this.updateWorkCardStepData(true, 'Add stamp', 'Request Re-Inspection', isEditable);
    }

    if (this.checkRejectMaintStampNotExist(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, 'Inspection Rejected', false);
    }
    
    if (this.checkStatusWithInsAndMechFlag(params)) {
      return this.updateWorkCardStepData(false, params.STAMPED_BY_MECHANIC_DATE, params.STAMPED_BY_INSPECTOR_DATE, false);
    }
    //--Start
    if (this.checkStatusWithInsDoneWithoutMechFlag(params)) {
      return this.updateWorkCardStepData(false, params.STAMPED_BY_MECHANIC_DATE, params.STAMPED_BY_INSPECTOR_DATE, false);
    }
    //--End
    if (this.checkWithInspectorSignOffFlag(params)) {
      return this.updateWorkCardStepData(true, 'Add stamp', '', false);
    }
    if (this.checkWithoutInspectorSignOffFlag(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, '', false);
    }

    if (this.checkStatusWithInsDoneWithoutMechFlagOpenNew(params)) {
      return this.updateWorkCardStepData(true, 'Add stamp', '', false);
    }
    if (this.checkWithInspectorSignOffFlagOpenNew(params)) {
      return this.updateWorkCardStepData(true, params.STAMPED_BY_MECHANIC_DATE, '', false);
    }
  }

  //Set step status if Mechanic flag is N.
  private checkRequestInspectionWithoutMechFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'IN-WORK' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'N'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

  //Set step status if Mechanic flag is Y.
  private checkRequestInspectionWithMechFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'IN-WORK' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

    //Set step status if Mechanic flag is N.Open -New status
    private checkRequestInspectionWithoutMechFlagOpenNew(params: workCardStepsModel): boolean {
      if (params.STATUS === 'OPEN' && params.STATUS_REASON === 'NEW'
        && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
        && params.STAMPED_BY_MECHANIC_FLAG === 'N'
        && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
        return true;
      }
      else {
        return false;
      }
    }

    //Set step status if Mechanic flag is Y. For Open -New status
    private checkRequestInspectionWithMechFlagOpenNew(params: workCardStepsModel): boolean {
      if (params.STATUS === 'OPEN' && params.STATUS_REASON === 'NEW'
        && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
        && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
        && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
        return true;
      }
      else {
        return false;
      }
    }

  //Set step status if Mechanic flag is Y and inspector rejected.
  private checkRequestInspectionWithMechFlagInspFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N'
      && params.STEP_ACTIVITY_STATUS !== null) {
      return true;
    }
    else {
      return false;
    }
  }
  


    //Set step status if Mechanic flag is Y and inspector rejected.
    private checkRequestInspectionWithMechFlagInspRejectFlag(params: workCardStepsModel): boolean {
      if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'MAINT'
        && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
        && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
        && params.INSPECTOR_SIGNOFF_FLAG === 'N'
        && params.STEP_ACTIVITY_STATUS === null) {
        return true;
      }
      else {
        return false;
      }
    }

    //Set step status if Mechanic flag is Y and inspector rejected.
    private checkFinalBuybackCorrected(params: workCardStepsModel): boolean {
      if (params.STATUS === 'CLOSED' && params.STATUS_REASON === 'CORRECTED'
        && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
        && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
        && params.INSPECTOR_SIGNOFF_FLAG === 'N'
        && params.CURRENT_ACTIVITY_RESULT === 'CORRECTED'
        && params.STEP_NAME === 'FINAL BUYBACK') {
        return true;
      }
      else {
        return false;
      }
    }

  private checkRequestInspectionWithoutMechFlagInspFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'N'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N'
      && params.STEP_ACTIVITY_STATUS !== null) {
      return true;
    }
    else {
      return false;
    }
  }


// Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
// 1. Check if initially rejected by inspector
private isInitialInspectionRejected(params: workCardStepsModel): boolean {
  return params.STATUS === 'HOLD' &&
         params.STATUS_REASON === 'MAINT' &&
         params.STAMPED_BY_INSPECTOR_FLAG === 'Y' &&
         params.INSPECTOR_STAMP_NUMBER !== null &&
         params.CURRENT_ACTIVITY_RESULT === 'REJECT' &&
         params.STEP_ACTIVITY_STATUS === null;
}

// Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
// 2. Check if corrected after rejection by inspector
private isCorrectionAfterInspectionReject(params: workCardStepsModel): boolean {
  return params.STATUS === 'HOLD' &&
         params.STATUS_REASON === 'MAINT' &&
         params.STAMPED_BY_INSPECTOR_FLAG === 'Y' &&
         params.INSPECTOR_STAMP_NUMBER !== null &&
         params.STEP_ACTIVITY_STATUS === 'EDITED' &&
         params.CURRENT_ACTIVITY_RESULT === 'REJECT';
}

// Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
// 3. Check if inspection was requested after CIR rejection
private isInspectionRequestedAfterReject(params: workCardStepsModel): boolean {
  return (params.STATUS === 'HOLD' || params.STATUS === 'IN-WORK') &&
         params.STATUS_REASON === 'INSPECTOR' &&
         params.STAMPED_BY_INSPECTOR_FLAG === 'Y' &&
         params.STAMPED_BY_INSPECTOR_EMP_NO !== null 
}

// Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
// 4. Check if inspection was canceled by technician after CIR rejection
private isInspectionCanceledByTechnician(params: workCardStepsModel): boolean {
  return params.STATUS === 'IN-WORK' &&
         params.STATUS_REASON === 'MAINT' &&
         params.INSPECTOR_STAMP_NUMBER !== null &&
         params.STAMPED_BY_INSPECTOR_FLAG === 'Y';
}

// Task 44245 : Edge Scenario Inspector Rejecting on Behalf of Customer
// 5. Check if inspection is in progress after CIR rejection
private isInspectionInProgressAfterReject(params: workCardStepsModel): boolean {
  return params.STATUS === 'IN-WORK' &&
         params.STATUS_REASON === 'INSPECTOR' &&
         params.INSPECTOR_STAMP_NUMBER !== null &&
         params.STAMPED_BY_INSPECTOR_FLAG === 'Y';
}

  //Set step status if awaiting inspection.
  private checkAwaitingInpection(params: workCardStepsModel): boolean {
    if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'INSPECTOR'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
      && params.INSPECTOR_SIGNOFF_FLAG !== 'Y') {
      return true;
    }
    else {
      return false;
    }
  }


  //Set step status if awaiting inspection without Mechanic stamp
  private checkAwaitingInpectionWithoutMechFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'INSPECTOR'
    && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
    && params.STAMPED_BY_MECHANIC_FLAG === 'N'
    && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
    return true;
  }
  else {
    return false;
  }
  }

  //Set step status if inspection is in progress.
  private checkInspectionInprogress(params: workCardStepsModel): boolean {
    if (params.STATUS === 'IN-WORK' && params.STATUS_REASON === 'INSPECTOR'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

   //Set step status if inspection is in progress without Mechanic stamp.
   private checkInspectionInprogressWithoutMechFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'IN-WORK' && params.STATUS_REASON === 'INSPECTOR'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'N'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

  //Set step status if Inspector and Mechanic flag is N.
  private checkStatusWithoutInsAndMechFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.CURRENT_ACTIVITY_RESULT !== 'REJECT'
      && params.STAMPED_BY_MECHANIC_FLAG === 'N'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

  private checkRejectMaintStampNotExist(params: workCardStepsModel): boolean {
    if (params.STATUS === 'HOLD' && params.STATUS_REASON === 'MAINT'
      && params.CURRENT_ACTIVITY_RESULT === 'REJECT'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N'
      && params.STEP_ACTIVITY_STATUS === null
      && params.STAMPED_BY_MECHANIC_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

  //Set step status if Inspector and Mechanic flag is Y.
  private checkStatusWithInsAndMechFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'CLOSED' && params.STATUS_REASON === 'COMPLETED'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'Y'
      && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
      && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
      return true;
    }
    else {
      return false;
    }
  }

 //Set step status if Inspector and Mechanic flag is Y.
 private checkStatusWithInsDoneWithoutMechFlag(params: workCardStepsModel): boolean {
  if (params.STATUS === 'CLOSED' && params.STATUS_REASON === 'COMPLETED'
    && params.STAMPED_BY_INSPECTOR_FLAG === 'Y'
    && params.STAMPED_BY_MECHANIC_FLAG === 'N'
    && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
    return true;
  }
  else {
    return false;
  }
}

  //Set step status if Inspector signoff flag is N.
  private checkWithInspectorSignOffFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'IN-WORK' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'N'
      && params.INSPECTOR_SIGNOFF_FLAG === 'Y') {
      return true;
    }
    else {
      return false;
    }
  }

   //Set step status if Inspector and Mechanic flag is Y.For status Open and new
 private checkStatusWithInsDoneWithoutMechFlagOpenNew(params: workCardStepsModel): boolean {
  if (params.STATUS === 'OPEN' && params.STATUS_REASON === 'NEW'
    && params.STAMPED_BY_INSPECTOR_FLAG === 'Y'
    && params.STAMPED_BY_MECHANIC_FLAG === 'N'
    && params.INSPECTOR_SIGNOFF_FLAG === 'N') {
    return true;
  }
  else {
    return false;
  }
}

  //Set step status if Inspector signoff flag is N.For status Open and new
  private checkWithInspectorSignOffFlagOpenNew(params: workCardStepsModel): boolean {
    if (params.STATUS === 'OPEN' && params.STATUS_REASON === 'NEW'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'N'
      && params.INSPECTOR_SIGNOFF_FLAG === 'Y') {
      return true;
    }
    else {
      return false;
    }
  }

  //Set step status if Inspector signoff flag is Y.
  private checkWithoutInspectorSignOffFlag(params: workCardStepsModel): boolean {
    if (params.STATUS === 'IN-WORK' && params.STATUS_REASON === 'MAINT'
      && params.STAMPED_BY_INSPECTOR_FLAG === 'N'
      && params.STAMPED_BY_MECHANIC_FLAG === 'Y'
      && params.INSPECTOR_SIGNOFF_FLAG === 'Y') {
      return true;
    }
    else {
      return false;
    }
  }
  //Update step parameters.
  private updateWorkCardStepData(isEditable: boolean, technicianStatus: string, inspectorStatus: string, inspectorStatusEnabled: boolean): WorkCardStepsStatus {
    let stepStatus: WorkCardStepsStatus = new WorkCardStepsStatus();
    stepStatus.isEditable = isEditable;
    stepStatus.technicianStatus = technicianStatus;
    stepStatus.inspectorStatus = inspectorStatus;
    stepStatus.inspectorStatusEnabled = inspectorStatusEnabled;
    return stepStatus;
  }

  /** convert number to hours and minutes */
  convertNumToTime(number) {
    let time = '';
    if (number) {
      let sign: number | string = (number >= 0) ? 1 : -1;
      number = number * sign;
      let hour = Math.floor(number);
      let decpart = number - hour;
      let min = 1 / 60;
      decpart = min * Math.round(decpart / min);
      let minute = Math.floor(decpart * 60) + '';
      if (minute && minute.length < 2) {
        minute = '0' + minute;
      }
      sign = sign === 1 ? '' : '-';
      time = sign + hour + ':' + minute;
    }
    return time;
  }

  //Get initials from first Name and Last Name
  getShortNameCharacters(fName: string, lName: string): string {
    fName = (fName !== null && fName !== undefined && fName !== '') ? fName : '';
    lName = (lName !== null && lName !== undefined && lName !== '') ? lName : '';
    let str = fName + ' ' + lName;
    if (str.trim() !== '') {
      let splitStr = str.split(' ');
      let first = splitStr[0].charAt(0);
      let last = splitStr.length > 1 ? (splitStr[splitStr.length - 1]).charAt(0) : '';
      return `${first}${last}`;
    } else {
      return '';
    }
  }

   //Get initials from first Name and and full for Last Name
   getFirstNameShortWithFullLastName(fName: string, lName: string): string {
    fName = (fName !== null && fName !== undefined && fName !== '') ? fName : '';
    lName = (lName !== null && lName !== undefined && lName !== '') ? lName : '';
    let str = fName + ' ' + lName;
    if (str.trim() !== '') {
      let splitStr = str.split(' ');
      let first = splitStr[0].charAt(0);
      return `${first}. ${lName}`;
    } else {
      return '';
    }
  }

  /*START: Methods to convert plain json into groupby field JSON objects*/
  groupBySingleField(data: any, field: string) {
    return data.reduce((acc, val) => {
      const rest = Object.keys(val).reduce((newObj, key) => {
        if (key !== field) {
          newObj[key] = val[key];
        }
        return newObj;
      }, {});
      if (acc[val[field]]) {
        acc[val[field]].push(rest);
      } else {
        acc[val[field]] = [rest];
      }
      return acc;
    }, {})
  }
  /*END: Methods to convert plain json into groupby field JSON objects*/

  // color code for turn over report 'Type' Chip control.
  turnOverTypeColorCode(typneName: string): IonicColor {
    let color:IonicColor;
    switch (typneName?.toLowerCase()) {
      case 'info': {
        color = IonicColor.success;
        break;
      }
      case 'concern': {
        color =  IonicColor.warning;
        break;
      }
      case 'critical': {
        color =  IonicColor.danger;
        break;
      }
      default: {
        color =  IonicColor.dark;
        break;
      }
    }
    return color;
  }

  getVerifierTypeFromRole(role: string): string {
    // !!!!!! WARNING !!!!!!
    // Changes to these mappings have important repercussions in the database.
    // Consult with the DB team before making changes here.
    const mapping = {
      'MRO - TECHNICIAN': 'TECH',
      'MRO - INSPECTOR': 'INSPECTOR',
      'MRO - LEAD': 'TECH',
      'MRO - SUPERVISOR': 'TECH',
      'MRO - CUSTOMER': 'CUST_REP',
    }

    return mapping[role] || 'TECH';
  }

  getDate(): string {
    let now = new Date();
    return now.getDate() + '/' + (now.getMonth() + 1) + '/' + now.getFullYear();
  }
}
