import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter, pairwise } from "rxjs/operators";

@Injectable()
export class RouteTransitionService {

  private urlCurrent: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly urlCurrent$: Observable<string> = this.urlCurrent.asObservable();

  private urlPrevious: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly urlPrevious$: Observable<string> = this.urlPrevious.asObservable();

  public outgoingUrl: NavigationEnd = null;
  public incomingUrl: NavigationEnd = null;

  private routingSubscription: Subscription = null;

  constructor(
    private router: Router
  ) {}

  subscribe() {
    this.routingSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      pairwise()
    ).subscribe(([outgoing, incoming]: [NavigationEnd, NavigationEnd]) => {
      this.incomingUrl = incoming
      this.outgoingUrl = outgoing
    });
  }

  unsubscribe() {
    this.routingSubscription?.unsubscribe();
  }

  updateCurrentUrl(currentUrl: string) {
    if (this.urlCurrent.value === '') {
      this.urlCurrent.next(currentUrl);
      this.urlPrevious.next(currentUrl);
    } else {
      this.urlPrevious.next(this.urlCurrent.value);
      this.urlCurrent.next(currentUrl);
    }
  }
}