import { Injectable } from '@angular/core';
import { ConcourseApiService } from '../../../../shared/services/concourse-api.service';
import { ProjectDetailsWorkCardModel } from '../../models/projectdetails-workcard';
import {
  RoutineWorkCardStepReqModel,
  CustomerCIRStepReqModel,
  WorkCardFilesReqModel,
  RoutineInspectionFlagResModel,
  RoutineCustomerStepsResModel,
  UploadFilesReqModel,
  CardClosureSceptreDetailsReqModel,
  AddSceptreFlagReqModel,
  AuditClosureReviewResModel,
} from '../../models/request-payload';
import { PathConstants } from '../../constants/mro-path';
import { DataHelper } from '../../services/staar-utils';
import { map, switchMap } from 'rxjs/operators';
import { WorkCardFilesModel } from '../../models/project-files';
import { Observable, combineLatest } from 'rxjs';
import {
  AuditApprovalStatus,
  AuditReviewStatus,
  PersonaEnum,
  RequestTypeEnum,
  RoutineInspectionFlag,
} from '../../models/mro-enums';
import { HttpService } from '../../../../shared/services/http.service';
import { HttpParams } from '@angular/common/http';
import { UserAccessorService } from './user-accessor.service';
import { CardStatusReasonList } from '../../models/status-reason-list';
import { ProjectManagementService } from '../../services/project-management.service';
import { RequiredPartsModel } from '../../models/my-work-card';
import { ToolsModel } from '../../models/tools-history';
import { GetLogPageNumbersResponseModel } from '../../models/log-page-numbers';
import { CircuitBreakerModel } from '../../models/circuit-breaker';
import { PersistedValueEnum } from '../../../../shared/models/persisted-value-enum';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../store/application-store';
import { patchWorkCard } from '../../../../store/workcards/workcards.actions';

export type RoutineWorkCardStep = {
  type: 'Routine';
  annotationId: string;
} & Omit<RoutineWorkCardStepReqModel, 'operation' | 'token'>;

export type CustomerCIRStep = {
  type: 'CustomerCIR';
  annotationId: string;
} & Omit<CustomerCIRStepReqModel, 'operation' | 'token'>;

export type WorkCardStatusAndReason = {
  status: string;
  statusReason: string;
  description: string;
};
export interface BasicAnnotation {
  // @todo - tighter type
  type: string;

  annotationId: string;
  page: number;
  x: number;
  y: number;
  customData: Record<string, string>;
}

export type PreAuditChecklistItem = {
  CHECK_LIST_ITEM: string;
  ITEM_STATUS_FLAG: 'Y' | 'N';
  DIV_NO: number;
  WORK_CARD_ID: number;
};

export type AnnotatedStep = RoutineWorkCardStep | CustomerCIRStep;
export type PersistenceOperation = 'INSERT' | 'UPDATE' | 'DELETE';

export type CIRStep = RoutineCustomerStepsResModel;

@Injectable()
export class WorkCardAccessorService {
  constructor(
    private concourseApi: ConcourseApiService,
    private httpService: HttpService,
    private dataHelper: DataHelper,
    private userAccessor: UserAccessorService,
    private applicationStore: Store<ApplicationState>,
    private projectMgmtService: ProjectManagementService
  ) {}

  getWorkCardDetails(
    workCardId: number,
    persona: PersonaEnum = PersonaEnum.tech
  ): Observable<ProjectDetailsWorkCardModel> {
    const employeeInfo = this.userAccessor.getEmployeeInfo();
    const params = new HttpParams({
      fromObject: {
        workCardId,
        token: this.getToken(),
        divNo: employeeInfo.divNo,
        employeeNumber: employeeInfo.employeeNumber,
      },
    });
    const baseUrl = '/concourse/staar/project/v1/workCardDetails';
    const personaFragmentMap = {
      [PersonaEnum.tech]: 'technician',
      [PersonaEnum.kickback]: 'kickback',
      [PersonaEnum.inspector]: 'inspector',
      [PersonaEnum.discrepancy]: 'discrepancy',
    };
    const personaFragment = personaFragmentMap[persona];

    const personaUrl = personaFragment
      ? `${baseUrl}/${personaFragment}`
      : baseUrl;

    if (personaFragment === undefined) {
      return this.httpService.get(baseUrl, null, params);
    } else {
      // dual call gets around missing basic params such as WORK_CARD_ID
      // This needs to be solved/simplified at the API level
      return combineLatest([
        this.httpService.get(baseUrl, null, params),
        this.httpService.get(personaUrl, null, params),
      ]).pipe(map(([base, specific]) => coalesceObjects(base, specific)));
    }
  }

  updateFinalCustomerPaperworkAudit(
    workCardDetails: ProjectDetailsWorkCardModel,
    toggleValue: boolean
  ): Observable<ProjectDetailsWorkCardModel> {
    return this.concourseApi.updateWorkCards([{
      divNo: workCardDetails.DIV_NO,
      paperWorkFlag: toggleValue ? 'Y' : 'N',
      token: this.getToken(),
      workCardId: workCardDetails.WORK_CARD_ID,
      airworthinessDirectiveFlag: workCardDetails.AIRWORTHINESS_DIRECTIVE_FLAG
    }]).pipe(map(res => {
      if (res && res[0]) {
        const resParams = res[0]
        const result = {
          ...workCardDetails,
          PAPERWORK_FLAG: resParams.paperWorkFlag
        }
        return result
      }
      throw new Error('no response')
    }))
  }

  getAllDocuments(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<WorkCardFilesModel[]> {
    const query = this.filesListReqQuery(workCardDetails, {
      documentType: '',
    });
    return this.concourseApi
      .getWorkCardFilesList(
        PathConstants.workcardFiles,
        this.dataHelper.constructQueryString(query)
      )
      .pipe(map((res) => res ?? []));
  }

  getWorkCardPdf(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<WorkCardFilesModel> {
    const query = this.filesListReqQuery(workCardDetails, {
      documentType: 'workcard',
    });
    return this.concourseApi
      .getWorkCardFilesList(
        PathConstants.workcardFiles,
        this.dataHelper.constructQueryString(query)
      )
      .pipe(map((res) => (res?.workCardFiles ? res.workCardFiles[0] : null)));
  }

  getAttachments(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<WorkCardFilesModel[]> {
    const query = this.filesListReqQuery(workCardDetails, {
      documentType: 'attachment',
    });
    return this.concourseApi
      .getWorkCardFilesList(
        PathConstants.workcardFiles,
        this.dataHelper.constructQueryString(query)
      )
      .pipe(map((res) => res?.workCardAttachmentFiles ?? []));
  }

  getTechDataFiles(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<WorkCardFilesModel[]> {
    const query = this.filesListReqQuery(workCardDetails, {
      documentType: 'techData',
    });
    return this.concourseApi
      .getWorkCardFilesList(
        PathConstants.workcardFiles,
        this.dataHelper.constructQueryString(query)
      )
      .pipe(map((res) => res?.techDataFiles ?? []));
  }

  public fetchCardClosureSceptreDetails(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<{ required: boolean; enteredBy: string }> {
    const reqModel = new CardClosureSceptreDetailsReqModel();
    reqModel.token = this.getToken();
    reqModel.divNo = workCardDetails.DIV_NO;
    reqModel.parentId = workCardDetails.WORK_CARD_ID;
    reqModel.customerNo = workCardDetails.CUSTOMER_NUMBER;

    return this.concourseApi
      .getCardClosureSceptreDetails(
        PathConstants.cardClosureSceptreDetails,
        this.dataHelper.constructQueryString(reqModel)
      )
      .pipe(
        map((res) => ({
          required: res?.SCEPTRE_ENTRY_REQUIRED === 'Y',
          enteredBy: res?.SCEPTRE_ENTERED_BY_EMPLOYEE_NAME ?? null,
        }))
      );
  }

  public fetchPreAuditChecklist(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<PreAuditChecklistItem[]> {
    return this.concourseApi
      .getRecordQueueCheckList(
        this.dataHelper.constructQueryString({
          divNo: workCardDetails.DIV_NO,
          workCardId: workCardDetails.WORK_CARD_ID,
          token: this.getToken(),
        })
      )
      .pipe(map((res) => res ?? []));
  }

  public fetchLogPages(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<GetLogPageNumbersResponseModel[]> {
    return this.concourseApi
      .getLogPageNumberService(
        PathConstants.getLogPageNumber,
        this.dataHelper.constructQueryString({
          divNo: workCardDetails.DIV_NO,
          workCardId: workCardDetails.WORK_CARD_ID,
          token: this.getToken(),
        })
      )
      .pipe(map((res) => res ?? []));
  }

  public fetchAuditClosureReviews(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<AuditClosureReviewResModel[]> {
    const params = {
      divNo: workCardDetails.DIV_NO,
      token: this.getToken(),
      workCardId: workCardDetails.WORK_CARD_ID,
    };

    const getReviews = () =>
      this.concourseApi.getAuditClosureReview(
        PathConstants.auditClosureReview,
        this.dataHelper.constructQueryString({
          ...params
        })
      );
    return getReviews().pipe(map((results) => results ?? []));
  }

  public fetchAllAuditClosureReview(
    workCardDetails: ProjectDetailsWorkCardModel, verifierType: string): Observable<AuditClosureReviewResModel[]> {
      const params = {
        divNo: workCardDetails.DIV_NO,
        token: this.getToken(),
        workCardId: workCardDetails.WORK_CARD_ID,
        verifierType: verifierType,
      };

      const getReviews = (verifierType: string) =>
        this.concourseApi.getAuditClosureReview(
          PathConstants.auditClosureReview,
          this.dataHelper.constructQueryString({
            ...params,
          })
        );
      return getReviews(verifierType).pipe(map((results) => results ?? []));
  }

  public fetchCircuitBreakers(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CircuitBreakerModel[]> {
    return this.concourseApi
      .getCircuitBreakers(
        PathConstants.circuitBreakers,
        this.dataHelper.constructQueryString({
          divNo: workCardDetails.DIV_NO,
          workCardId: workCardDetails.WORK_CARD_ID,
          token: this.getToken(),
        })
      )
      .pipe(map((res) => res ?? []));
  }

  public updateSceptreRequirement(
    workCardDetails: ProjectDetailsWorkCardModel,
    required: boolean
  ): Observable<true> {
    const reqModel = new AddSceptreFlagReqModel();
    reqModel.token = this.getToken();
    reqModel.divNo = workCardDetails.DIV_NO;
    reqModel.parentId = workCardDetails.WORK_CARD_ID;
    reqModel.data = required ? 'Y' : 'N';

    return this.concourseApi.addSceptreData(reqModel).pipe(
      map((res) => {
        if (res.O_SUCCESS_FLAG === 'N') {
          throw new Error(res.O_ERROR);
        }
        return true;
      })
    );
  }

  private filesListReqQuery(
    workCardDetails: ProjectDetailsWorkCardModel,
    options: Partial<
      Pick<
        WorkCardFilesReqModel,
        'documentType' | 'sortingField' | 'sortingOrder'
      >
    > = {}
  ): WorkCardFilesReqModel {
    if (options?.documentType !== ''){
      return {
        workCardId: workCardDetails.WORK_CARD_ID,
        token: this.getToken(),
        divNo: workCardDetails.DIV_NO,
        sortingField: options?.sortingField || 'uploadedDate',
        sortingOrder: options?.sortingOrder || 'desc',
        documentType: options?.documentType || 'workcard',
      };
    }
    return {
      workCardId: workCardDetails.WORK_CARD_ID,
      token: this.getToken(),
      divNo: workCardDetails.DIV_NO,
      sortingField: options?.sortingField || 'uploadedDate',
      sortingOrder: options?.sortingOrder || 'desc',
      //documentType: options?.documentType || 'workcard',
    };
  }

  getFileAnnotations(
    workCardDetails: ProjectDetailsWorkCardModel,
    fileObject: WorkCardFilesModel
  ): Observable<Blob> {
    const fileName = encodeURIComponent(
      `${fileObject.FILE_NAME}.${fileObject.FILE_EXTENSION}`
    );
    const { WORK_ORDER_NUMBER, ZONE_NUMBER, ITEM_NUMBER } = workCardDetails;
    const workCardIdentifier = `${WORK_ORDER_NUMBER}-${ZONE_NUMBER}-${ITEM_NUMBER}`;

    const objectType = fileObject.OBJECT_TYPE;

    return this.concourseApi.getAnnotationDataService(
      PathConstants.fetchAnnotationDataPath,
      this.dataHelper.constructQueryString({
        projectNo: workCardDetails.PROJECT_NUMBER,
        workCardIdentifier,
        fileName,
        objectType,
      })
    );
  }

  getRoutineInspectionFlag(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<RoutineInspectionFlagResModel[]> {
    return this.concourseApi.getRoutineInspectionFlag(
      PathConstants.routineFlagCheck,
      this.dataHelper.constructQueryString({
        divNo: workCardDetails.DIV_NO,
        workCardId: workCardDetails.WORK_CARD_ID,
        projectNo: workCardDetails.PROJECT_NUMBER,
        groupId: workCardDetails.GROUP_ID,
        token: this.getToken(),
      })
    );
  }

  getCIRSteps(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CIRStep[]> {
    return workCardDetails.WORK_ORDER_TYPE === 'ROUTINE'
      ? this.getRoutineCIRSteps(workCardDetails)
      : this.getNonRoutineCIRSteps(workCardDetails);
  }

  getContinuationSheets(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CIRStep[]> {
    return this.getRCContinuationSheets(workCardDetails);
  }

  voidCIRStep(
    workCardDetails: ProjectDetailsWorkCardModel,
    customerCardStepId: number,
    description: string = ''
  ): Observable<number> {
    const storedUserDetails = JSON.parse(localStorage.getItem(PersistedValueEnum.userDetails));
    if (workCardDetails.WORK_ORDER_TYPE === 'ROUTINE'){
      return this.concourseApi
        .confirmVoidRoutineCIRData({
          divNo: workCardDetails.DIV_NO,
          token: this.getToken(),
          workCardId: workCardDetails.WORK_CARD_ID,
          description,
          operation: 'VOID',
          customerNumber: `${workCardDetails.CUSTOMER_NUMBER}`,
          customerCardStepId,
          customerRepStampNumber: storedUserDetails.stampNumber
        })
        .pipe(
          map((res) => {
            if (res.O_SUCCESS_FLAG === 'N') {
              throw new Error(res.O_ERROR_MESSAGE);
            }
            return res.IO_CUSTOMER_CARD_STEP_ID;
          })
        );
    }else{
      return this.concourseApi
        .confirmVoidCIRData({
          divNo: workCardDetails.DIV_NO,
          token: this.getToken(),
          workCardId: workCardDetails.WORK_CARD_ID,
          description,
          operation: 'DELETE',
          customerNumber: `${workCardDetails.CUSTOMER_NUMBER}`,
          customerCardStepId,
          customerRepStampNumber: storedUserDetails.stampNumber
        })
        .pipe(
          map((res) => {
            if (res.O_SUCCESS_FLAG === 'N') {
              throw new Error(res.O_ERROR_MESSAGE);
            }
            return res.IO_CUSTOMER_CARD_STEP_ID;
          })
        );
    }
  }

  private getRoutineCIRSteps(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CIRStep[]> {
    return this.concourseApi.getRoutineCustomerDesignatorSteps(
      PathConstants.routineCustomerSteps,
      this.dataHelper.constructQueryString({
        divNo: workCardDetails.DIV_NO,
        workCardId: workCardDetails.WORK_CARD_ID,
        sortingField: 'pageNumber',
        sortingOrder: 'asc',
        token: this.getToken(),
        all: true,
      })
    );
  }

  private getNonRoutineCIRSteps(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CIRStep[]> {
    return this.concourseApi
      .getWorkCardSteps(
        PathConstants.workCardSteps,
        this.dataHelper.constructQueryString({
          divNo: workCardDetails.DIV_NO,
          projectNo: workCardDetails.PROJECT_NUMBER,
          workCardId: workCardDetails.WORK_CARD_ID,
          token: this.getToken(),
          customerSteps: true,
          all: true,
        })
      )
      .pipe(
        map((res) => res || []),
        map((allSteps) => {

          const unassigned = allSteps.filter(
            (step) => step.CUSTOMER_CARD_STEPS_ID && !step.CARD_STEP_ID
          );
          const assigned = allSteps
            .filter((step) => step.CARD_STEP_ID)
            .map((step) => step.CUSTOMER_STEPS || [])
            .flat();
          return [...unassigned, ...assigned];
        })
      );
  }

  private getRCContinuationSheets(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CIRStep[]> {
    return this.concourseApi
      .getWorkCardSteps(
        PathConstants.workCardSteps,
        this.dataHelper.constructQueryString({
          divNo: workCardDetails.DIV_NO,
          projectNo: workCardDetails.PROJECT_NUMBER,
          workCardId: workCardDetails.WORK_CARD_ID,
          token: this.getToken(),
          customerSteps: true,
          all: true,
        })
      )
      .pipe(
        map((res) => res || []),
        map((allSteps) => {
          const unassigned = allSteps.filter(
            (step) => step.DOCUMENT_TYPE === 'CONTINUATION' || (step.CUSTOMER_CARD_STEPS_ID && !step.CARD_STEP_ID)
          );
          return [...unassigned];
        })
      );
  }

  fetchRequiredParts(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<RequiredPartsModel[]> {
    return this.concourseApi
      .getRequiredPartsList(
        PathConstants.workCardPartsList,
        this.dataHelper.constructQueryString({
          pageNo: 1,
          pageSize: 100,
          token: this.getToken(),
          workOrderNumber: workCardDetails.WORK_ORDER_NUMBER,
          projectNumber: workCardDetails.PROJECT_NUMBER,
          divNo: workCardDetails.DIV_NO,
          zoneNumber: workCardDetails.ZONE_NUMBER,
          itemNumber: workCardDetails.ITEM_NUMBER,
        })
      )
      .pipe(map((response) => response?.paginationData || []));
  }

  fetchTools(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<ToolsModel[]> {
    return this.concourseApi
      .getTools(
        PathConstants.workcardTools,
        this.dataHelper.constructQueryString({
          token: this.getToken(),
          workCardId: workCardDetails.WORK_CARD_ID,
          divNo: workCardDetails.DIV_NO,
        })
      )
      .pipe(map((tools) => tools || []));
  }

  submitCustomerRoutineInspectionResponse(inspectionParams: {
    workCardDetails?: ProjectDetailsWorkCardModel;
    cirStepsNotStarted?: CIRStep[];
    customerHoldFlags?: RoutineInspectionFlagResModel[];
  }) {
    const { workCardDetails, customerHoldFlags, cirStepsNotStarted } =
      inspectionParams;

    const stepData = cirStepsNotStarted.length ? cirStepsNotStarted[0] : {};

    const activityCode = customerHoldFlags.length
      ? customerHoldFlags[0].REQUEST_TYPE
      : null;

    return this.concourseApi
      .routineStepsGroupActivityService({
        divNo: workCardDetails.DIV_NO,
        workCardId: workCardDetails.WORK_CARD_ID,
        operation: 'UPDATE',
        verifierType: 'CUST_REP',
        verifierApprovalStatus: RoutineInspectionFlag.accept,
        verifierApprovalReason: null,
        rtnCardStampsGroupId: customerHoldFlags.length
          ? customerHoldFlags[0].GROUP_ID
          : null,
        customerCardSteps: [
          {
            customerCardStepId: stepData.CUSTOMER_CARD_STEPS_ID,
          },
        ],
        parentStepId: stepData.PARENT_STEP_ID,
        fallBackCard: 'LAST_LOGON',
        activityCode: 'INSPECTION',
        token: this.getToken(),
      })
      .pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            /*START : Code logic added for Bug- 36417 */
            if (
              cirStepsNotStarted.length === 1 &&
              customerHoldFlags[0].REQUEST_TYPE === RequestTypeEnum.finalBuyback
            ) {
              this.projectMgmtService.updateWCStatusAndReason('resubmit');
            }
            /*END : Code logic added for Bug- 36417 */
            return true;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  requestWorkCardPDFAccess(workCardFile: WorkCardFilesModel) {
    return this.workCardPDFAccess({
      divNo: workCardFile.DIV_NO,
      fileId: workCardFile.ID,
      signOut: 'N',
    });
  }

  releaseWorkCardPDFAccess(workCardFile: WorkCardFilesModel) {
    return this.workCardPDFAccess({
      divNo: workCardFile.DIV_NO,
      fileId: workCardFile.ID,
      signOut: 'Y',
    });
  }

  uploadAttachments(
    workCardDetails: ProjectDetailsWorkCardModel,
    files: File[],
    fileNameOverrides: string[] = []
  ): Observable<boolean> {
    const payload: Omit<UploadFilesReqModel, 'id' | 'fileName'> = {
      divNo: workCardDetails.DIV_NO,
      parent: 'PR_WORK_CARDS',
      operation: 'INSERT',
      objectType: 'MAINT-WORKSHEETS',
      parentId: workCardDetails.WORK_CARD_ID,
      workCardIdentifier:
        workCardDetails.WORK_ORDER_NUMBER +
        '-' +
        workCardDetails.ZONE_NUMBER +
        '-' +
        workCardDetails.ITEM_NUMBER,
      projectNo: workCardDetails.PROJECT_NUMBER,
      token: this.getToken(),
    };

    const formData = new FormData();
    files.forEach((file, idx) => {
      const fileName = fileNameOverrides[idx] || file.name;
      formData.append('files', file, fileName);
    });

    formData.append('input', `[${JSON.stringify(payload)}]`);
    return this.concourseApi
      .uploadFiles(PathConstants.uploadFiles, formData)
      .pipe(
        map((res) => {
          const failedUploads = res?.length
            ? res.filter((f) => f.successFlag === 'N').map((f) => f.fileName)
            : files.map((f) => f.name);

          if (failedUploads.length) {
            throw new Error(
              'Failed uploading one or more of the following files:' +
                failedUploads.join(', ')
            );
          }

          return true;
        })
      );
  }

  generateContinuationSheet(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<boolean> {
    return this.concourseApi
      .generateContinuationSheet({
        token: this.getToken(),
        divNo: workCardDetails.DIV_NO,
        workCardId: `${workCardDetails.WORK_CARD_ID}`,
        projectNo: workCardDetails.PROJECT_NUMBER,
        workOrderNumber: workCardDetails.WORK_ORDER_NUMBER,
        zoneNumber: workCardDetails.ZONE_NUMBER,
        itemNumber: workCardDetails.ITEM_NUMBER,
        serialNo: `${workCardDetails.SERIAL_NUMBER}`,
        customerNo: `${workCardDetails.CUSTOMER_NUMBER}`,
        customerCardNo: workCardDetails.CUSTOMER_WORK_CARD,
      })
      .pipe(
        map((res) => {
          if (res?.success === true) {
            return true;
          }

          throw new Error(res.message);
        })
      );
  }

  getTechLibraryDocuments(): Observable<string[]> {
    let userDivNo = localStorage.getItem('divNo');
    const apiPath =
      'concourse/staar/pdftron/v1/listTechLibraryDocuments?divNo=' + userDivNo;
    return this.httpService.get(apiPath);
  }

  addTechLibraryDocuments(
    workCardDetails: ProjectDetailsWorkCardModel,
    documents: string[]
  ): Observable<string[]> {
    const payload = documents.map((document) => ({
      divNo: workCardDetails.DIV_NO,
      parent: 'PR_WORK_CARDS',
      operation: 'INSERT',
      parentId: workCardDetails.WORK_CARD_ID,
      workCardIdentifier:
        workCardDetails.WORK_ORDER_NUMBER.toString() +
        '-' +
        workCardDetails.ZONE_NUMBER.toString() +
        '-' +
        workCardDetails.ITEM_NUMBER.toString(),
      projectNo: workCardDetails.PROJECT_NUMBER,
      objectType: 'MAINT-WORKSHEETS',
      token: this.getToken(),
      fileName: document,
    }));

    return this.concourseApi.uploadTechLibrary(payload).pipe(
      map((resDocs) => {
        const failures = resDocs.filter((doc) => doc.successFlag === 'N');
        const successes = resDocs.filter((doc) => doc.successFlag === 'Y');

        if (failures.length) {
          // ideally a more elegant way to handle multiple failures
          throw new Error(failures[0].message);
        }

        return successes.map((doc) => doc.fileName);
      })
    );
  }

  updateWipStatus(
    workCardDetails: ProjectDetailsWorkCardModel,
    updates: {
      status: string;
      reason: string;
      correctiveAction?: string;
    }
  ): Observable<ProjectDetailsWorkCardModel> {
    const { divNo } = this.userAccessor.getEmployeeInfo();
    return this.concourseApi
      .withdrawCard({
        status: updates.status,
        reason: updates.reason,
        correctiveAction: updates.correctiveAction || '',
        statusType: workCardDetails.STATUS,
        parentId: workCardDetails.WORK_CARD_ID,
        statusReasonList: true,
        token: this.userAccessor.getToken(),
        divNo,
      })
      .pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return {
              ...workCardDetails,
              WIP_STATUS: updates.status,
              WIP_REASON: updates.reason,
              CORRECTIVE_ACTION: updates.correctiveAction,
            };
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  updateWipLeadStatus(
    workCardDetails: ProjectDetailsWorkCardModel,
    updates: {
      status: string;
      reason: string;
      correctiveAction?: string;
    }
  ): Observable<ProjectDetailsWorkCardModel> {
    const { divNo } = this.userAccessor.getEmployeeInfo();
    return this.concourseApi
      .callSupervisorSendCardToLead({
        statusReason: updates.reason,
        workCardId: workCardDetails.WORK_CARD_ID,
        token: this.userAccessor.getToken(),
        divNo,
        supervisorReview: 'N',
      })
      .pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return {
              ...workCardDetails,
              WIP_STATUS: updates.status,
              WIP_REASON: updates.reason,
              CORRECTIVE_ACTION: updates.correctiveAction,
            };
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  completeSupervisorReview(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<ProjectDetailsWorkCardModel> {
    const { divNo } = this.userAccessor.getEmployeeInfo();
    return this.concourseApi
      .submitClosureReview({
        divNo,
        workCardId: workCardDetails.WORK_CARD_ID,
        token: this.getToken(),
      })
      .pipe(
        map((res) => {
          if (res?.O_SUCCESS_FLAG === 'N') {
            throw new Error(res.O_ERROR_MESSAGE);
          }
          return {
            ...workCardDetails,
            WIP_STATUS: 'HOLD',
            WIP_REASON: 'AUDIT',
          };
        })
      );
  }

  getAvailableWipStatuses(
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<CardStatusReasonList[]> {
    const { divNo } = this.userAccessor.getEmployeeInfo();
    const params = {
      divNo,
      status: workCardDetails.WIP_STATUS,
      statusReason: workCardDetails.WIP_REASON,
      token: this.userAccessor.getToken(),
      workCardId: workCardDetails.WORK_CARD_ID,
    };
    return this.concourseApi
      .statusReasonList(params)
      .pipe(map((res) => res.cardStatusReasonList));
  }

  private workCardPDFAccess(reqParams) {
    const defaultParams = {
      programId: 'projectWorkCardList',
      terminalId: 'ATVDI-3',
      token: this.getToken(),
      signOut: 'N',
    };
    return this.concourseApi
      .workCardPDFAccess({ ...defaultParams, ...reqParams })
      .pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return true;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  public acceptCIRRoutine(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel,
    routineFlags: RoutineInspectionFlagResModel[]
  ) {
    const paramsPatch: Partial<RoutineWorkCardStepReqModel> = {};
    if (routineFlags?.length) {
      paramsPatch.groupId = routineFlags[0].GROUP_ID;
    }

    return this.persistRoutineWorkCardStep({
      operation: 'INSERT',
      annotation,
      workCardDetails,
      paramsPatch,
    });
  }

  public acceptCIRNonRoutine(
    workCardDetails: ProjectDetailsWorkCardModel,
    cirStep: RoutineCustomerStepsResModel
  ): Observable<number> {
    const storedUserDetails = JSON.parse(localStorage.getItem(PersistedValueEnum.userDetails));
    return this.concourseApi
      .addCustomerCIRStep({
        divNo: workCardDetails.DIV_NO,
        workCardId: workCardDetails.WORK_CARD_ID,
        description: cirStep.DESCRIPTION,
        token: this.getToken(),
        operation: 'UPDATE',
        approvalStatus: 'ACCEPT',
        approvalReason: null,
        customerNumber: `${workCardDetails.CUSTOMER_NUMBER}`,
        customerCardStepId: cirStep.CUSTOMER_CARD_STEPS_ID,
        parentStepId: cirStep.PARENT_STEP_ID,
        fallBackCard: 'LAST_LOGON',
        customerRepStampNumber: storedUserDetails.stampNumber
      })
      .pipe(
        map((res) => {
          if (res?.O_SUCCESS_FLAG === 'Y') {
            return res.IO_CUSTOMER_CARD_STEP_ID;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  public addRoutineWorkCardStep(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel
  ): Observable<number> {
    return this.persistRoutineWorkCardStep({
      operation: 'INSERT',
      annotation,
      workCardDetails,
    });
  }

  public updateRoutineWorkCardStepWithXfdf(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel,
    fileObject: WorkCardFilesModel,
    xfdfBlob: Blob
  ): Observable<{
    IO_RTN_CARD_STAMPS_ACTIVITY_ID: number;
    xfdfUpdated: boolean;
  }> {
    return this.persistRoutineWorkCardStepWithXfdf({
      operation: 'UPDATE',
      annotation,
      workCardDetails,
      fileObject,
      xfdfBlob,
    });
  }

  public deleteRoutineWorkCardStepWithXfdf(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel,
    fileObject: WorkCardFilesModel,
    xfdfBlob: Blob
  ): Observable<{
    IO_RTN_CARD_STAMPS_ACTIVITY_ID: number;
    xfdfUpdated: boolean;
  }> {
    return this.persistRoutineWorkCardStepWithXfdf({
      operation: 'DELETE',
      annotation,
      workCardDetails,
      fileObject,
      xfdfBlob,
    });
  }

  public addRoutineCustomerCirStep(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel
  ) {
    return this.persistRoutineCustomerCirStep({
      operation: 'INSERT',
      annotation,
      workCardDetails,
    });
  }

    public updateCustomerReviewFlag(
      workCardDetails: ProjectDetailsWorkCardModel,
      value: boolean){
        this.concourseApi.updateWorkCards([{
        divNo: workCardDetails.DIV_NO,
        customerReviewFlag: value ? 'Y' : 'N',
        token: this.getToken(),
        workCardId: workCardDetails.WORK_CARD_ID
      }]).subscribe(
        apiResponse => {
          if(apiResponse){
            this.applicationStore.dispatch(patchWorkCard({
              workCardId: workCardDetails.WORK_CARD_ID,
              patch: {
                CUSTOMER_REVIEW_FLAG : value ? 'Y' : 'N'
              }
            }));
          }
        }
      );
    }

  public updateRoutineCustomerCirStepWithXfdf(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel,
    fileObject: WorkCardFilesModel,
    xfdfBlob: Blob
  ): Observable<{
    IO_CUSTOMER_CARD_STEP_ID: number;
    xfdfUpdated: boolean;
  }> {
    return this.persistRoutineCustomerCirStepWithXfdf({
      operation: 'UPDATE',
      annotation,
      workCardDetails,
      fileObject,
      xfdfBlob,
    });
  }

  public deleteRoutineCustomerCirStepWithXfdf(
    annotation: BasicAnnotation,
    workCardDetails: ProjectDetailsWorkCardModel,
    fileObject: WorkCardFilesModel,
    xfdfBlob: Blob
  ): Observable<{
    IO_CUSTOMER_CARD_STEP_ID: number;
    xfdfUpdated: boolean;
  }> {
    return this.persistRoutineCustomerCirStepWithXfdf({
      operation: 'DELETE',
      annotation,
      workCardDetails,
      fileObject,
      xfdfBlob,
    });
  }

  updateAuditProcess(
    workCardDetails: ProjectDetailsWorkCardModel,
    params: {
      verifierType: string;
      approvalStatus: AuditApprovalStatus;
      correctiveAction?: string;
      approvalReason?: string;
    }
  ): Observable<boolean> {
    return this.concourseApi
      .auditProcessActivity({
        cardErrorReferenceId: '',
        divNo: workCardDetails.DIV_NO,
        token: this.getToken(),
        verifierApprovalStatus: params.approvalStatus,
        verifierApprovalReason: params.approvalReason,
        workCardId: `${workCardDetails.WORK_CARD_ID}`,
        verifierType: params.verifierType,
        correctiveAction: params.correctiveAction,
      })
      .pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return true;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  private persistRoutineWorkCardStep(params: {
    operation: PersistenceOperation;
    annotation: BasicAnnotation;
    workCardDetails: ProjectDetailsWorkCardModel;
    paramsPatch?: Partial<RoutineWorkCardStepReqModel>;
  }): Observable<number> {
    const { operation, annotation, workCardDetails, paramsPatch } = params;
    const reqParams = {
      ...this.createRoutineStepParams(operation, workCardDetails, annotation),
      ...(paramsPatch ?? {}),
    };

    return this.concourseApi
      .addUpdateDeleteRoutineWorkCardSteps(reqParams)
      .pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return res.IO_RTN_CARD_STAMPS_ACTIVITY_ID;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
  }

  private persistRoutineWorkCardStepWithXfdf(params: {
    operation: PersistenceOperation;
    annotation: BasicAnnotation;
    workCardDetails: ProjectDetailsWorkCardModel;
    fileObject: WorkCardFilesModel;
    xfdfBlob: Blob;
  }) {
    const { operation, annotation, workCardDetails, fileObject, xfdfBlob } =
      params;
    return this.persistRoutineWorkCardStep({
      operation,
      annotation,
      workCardDetails,
    }).pipe(
      switchMap((id) =>
        this.saveAnnotationFile(workCardDetails, fileObject, xfdfBlob).pipe(
          map((xfdfUpdated) => ({
            xfdfUpdated,
            IO_RTN_CARD_STAMPS_ACTIVITY_ID: id,
          }))
        )
      )
    );
  }

  private persistRoutineCustomerCirStep(params: {
    operation: PersistenceOperation;
    annotation: BasicAnnotation;
    workCardDetails: ProjectDetailsWorkCardModel;
  }): Observable<number> {
    const { operation, annotation, workCardDetails } = params;
    const token = this.getToken();
    const storedUserDetails = JSON.parse(localStorage.getItem(PersistedValueEnum.userDetails));
    const reqParams = {
      token,
      operation,
      divNo: workCardDetails.DIV_NO,
      workCardId: workCardDetails.WORK_CARD_ID,
      customerNumber: String(workCardDetails.CUSTOMER_NUMBER),
      customerCardStepId:
        annotation.customData.IO_CUSTOMER_CARD_STEP_ID ?? null,

      description: 'CUST STEP FOR ROUTINE CARDS',
      parent: 'PR_RTN_CARD_STAMP_GRP_ACTION',
      positionX: annotation.x,
      positionY: annotation.y,
      pageNumber: annotation.page,
      annotationType: 'DESIGNATOR',
      customerRepStampNumber: storedUserDetails.stampNumber
    };
    if (operation === 'DELETE'){
      return this.concourseApi.deleteCustomerCIRStep(reqParams).pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return res.IO_CUSTOMER_CARD_STEP_ID;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
    }else{
      return this.concourseApi.addCustomerCIRStep(reqParams).pipe(
        map((res) => {
          if (res.O_SUCCESS_FLAG === 'Y') {
            return res.IO_CUSTOMER_CARD_STEP_ID;
          }
          throw new Error(res.O_ERROR_MESSAGE);
        })
      );
    }

  }

  private persistRoutineCustomerCirStepWithXfdf(params: {
    operation: PersistenceOperation;
    annotation: BasicAnnotation;
    workCardDetails: ProjectDetailsWorkCardModel;
    fileObject: WorkCardFilesModel;
    xfdfBlob: Blob;
  }) {
    const { operation, annotation, workCardDetails, fileObject, xfdfBlob } =
      params;
    return this.persistRoutineCustomerCirStep({
      operation,
      annotation,
      workCardDetails,
    }).pipe(
      switchMap((id) =>
        this.saveAnnotationFile(workCardDetails, fileObject, xfdfBlob).pipe(
          map((xfdfUpdated) => ({
            xfdfUpdated,
            IO_CUSTOMER_CARD_STEP_ID: id,
          }))
        )
      )
    );
  }

  public saveAnnotationFile(
    workCardDetails: ProjectDetailsWorkCardModel,
    fileObject: WorkCardFilesModel,
    xfdfBlob: Blob
  ) {
    const pdfFileName = `${fileObject.FILE_NAME}.${fileObject.FILE_EXTENSION}`;

    const queryString = this.dataHelper.constructQueryString({
      projectNo: workCardDetails.PROJECT_NUMBER,
      workCardIdentifier:
        workCardDetails.WORK_ORDER_NUMBER +
        '-' +
        workCardDetails.ZONE_NUMBER +
        '-' +
        workCardDetails.ITEM_NUMBER,
      fileName: pdfFileName,
      objectType: fileObject.OBJECT_TYPE,
    });

    return this.concourseApi
      .uploadAnnotationXmlService(queryString, xfdfBlob)
      .pipe(
        map((res) => {
          if (res.success) {
            return true;
          }
          throw new Error(res.message);
        })
      );
  }

  private createRoutineStepParams(
    operation: PersistenceOperation,
    workCardDetails: ProjectDetailsWorkCardModel,
    annotation: BasicAnnotation
  ): RoutineWorkCardStepReqModel {
    const { userRole } = this.userAccessor.getEmployeeInfo();
    const verifierType = this.dataHelper.getVerifierTypeFromRole(userRole);

    const step: RoutineWorkCardStepReqModel = {
      operation,
      token: this.getToken(),
      pageNumber: annotation.page,
      positionX: annotation.x,
      positionY: annotation.y,
      rtnCardStampsActivityId:
        annotation.customData.IO_RTN_CARD_STAMPS_ACTIVITY_ID ?? null,
      divNo: workCardDetails.DIV_NO,
      workCardId: workCardDetails.WORK_CARD_ID,
      verifierType,
      stampNumber: annotation.customData.STAMP_NUMBER ?? null,
      annotationType: annotation.type === 'STAMP' ? 'STAMP' : 'ANNOTATION',

      // Should these have values?
      documentType: '',
    };

    if (workCardDetails.persona === 'inspector') {
      step.groupId = workCardDetails.GROUP_ID;
    }

    return step;
  }

  private getToken() {
    return sessionStorage.getItem('token');
  }
}

function compactObject(obj: {}) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

function coalesceObjects(...args: {}[]) {
  const compactObjects = args.filter((o) => !!o).map(compactObject);
  return Object.assign({}, ...compactObjects);
}
